import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import $ from "jquery"
import React, { useEffect, useRef } from "react"
import TextTruncate from "react-text-truncate"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

// import Swiper core and required modules

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, blogs, post } }) => {

  const headerImg = getImage(post.featuredImage.node.localFile)

  let relPosts = []

  post.tags.nodes.map((tagItem, i) => {
    tagItem.posts.nodes.map((post, i) => {
      relPosts = [...relPosts, post]
    })
  })

  const relKeys = ["uri"]
  const relatedPosts = relPosts.filter(
    (s => o => (k => !s.has(k) && s.add(k))(relKeys.map(k => o[k]).join("|")))(
      new Set()
    )
  )

  const swipeRelPost = useRef(null)

  useEffect(() => {
    $(".swiper-button-prev").on("click", () => {
      swipeRelPost.current.slidePrev()
    })
    $(".swiper-button-next").on("click", () => {
      swipeRelPost.current.slideNext()
    })
  }, [])

  return (
    <div>
      <Seo
        title={post.seo?.title ? post.seo.title : post.title}
        description={post.seo?.metaDesc}
      />

      <Layout>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section className="intro_text blog_intro">
            <div className="container">
              <a className="btn blog_intro__return" href="/blog/">
                BACK TO BLOG
              </a>
              <div className="section__title">{post.title}</div>
              <div className="blog_intro__image">
                <GatsbyImage image={headerImg} alt="" />
              </div>
              <div className="blog_intro__tags">
                {post.tags.nodes.map(tag => {
                  return (
                    <span key={tag.id}>
                      <a href={tag.uri}>{tag.name}</a>
                      <span>,</span>{" "}
                    </span>
                  )
                })}
              </div>

              <div className="intro_text_holder">
                {!!post.content && (
                  <div itemProp="articleBody">{parse(post.content)}</div>
                )}
              </div>
            </div>
          </section>

          <section className="blog_posts">
            <div className="container">
              <div className="section__title text-center">
                Related Blog Posts
              </div>

              <div className="blog_posts__holder">
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  ref={swipeRelPost}
                  onSwiper={swiper => (swipeRelPost.current = swiper)}
                >
                  {relatedPosts.map((post, i) => {
                    const image = post.blog.thumbnail
                      ? getImage(post.blog.thumbnail.localFile)
                      : post.blog.thumbnail.soureUrl

                    return (
                      <SwiperSlide key={post.id} virtualIndex={i}>
                        <div
                          className="blog_posts__post"
                          key={post.id}
                          style={{ opacity: 1 }}
                        >
                          <div className="blog_posts__post_img">
                            <GatsbyImage image={image} alt="" />
                          </div>
                          <div
                            className="blog_posts__post_title underline_heading putty"
                            style={{ paddingBottom: 100 }}
                          >
                            <span>
                              <TextTruncate
                                line={4}
                                breakword="…"
                                text={parse(post.title)}
                              />
                            </span>
                          </div>
                          <div className="blog_posts__post_btn">
                            <a href={post.uri} className="btn">
                              READ MORE
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </section>
        </article>
      </Layout>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      seo {
        metaDesc
        title
      }

      tags {
        nodes {
          id
          name
          uri
          posts {
            nodes {
              uri
              title
              blog {
                thumbnail {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 400
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }

      featuredImage {
        node {
          altText
          sourceUrl

          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    blogs: allWpPost(sort: { fields: [date], order: DESC }, limit: 10) {
      nodes {
        title
        blog {
          thumbnail {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }

        tags {
          nodes {
            name
          }
        }
        date
        uri
      }
    }
  }
`
